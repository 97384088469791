<template>
  <div>
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-cell-group title="会议记录">
      <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
        <van-cell :title="it.TITLE" :value="it.TIME | datetime('YYYY-MM-DD HH:mm')" :label="it.LOC"
                  v-for="it in lst.da" :key="it.ID" is-link :to="'/meet/query/detail?guid='+it.GUID">
        </van-cell>
      </van-list>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  name: "index",
  data(){
    return{
      sea:{
        PAGE_SIZE:10,
        PAGE_INDEX:-1,
        KEY:"",
        DUR:this.$route.query.dur
      },
      lst:{
        da:[],
        loading:false,
        finish:false
      },
    }
  },
  created() {},
  methods:{
    filter(){
      this.lst.da.length=0;
      this.lst.finish=false;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      self.whale.remote.getCollection({
        url:'/api/Mobile/MEET/MinfoApi/GetList',
        data:self.sea,
        completed(its,n){
          console.log('1',its,n)
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    }
  }
}
</script>
